const FIREBASE_CONFIG = {
  easyWeb: {
    apiKey: "AIzaSyBwcP9R1s4hwkzCfvtkGZpt_0Hj5Yd6n5c",
    authDomain: "easyweb-a3e80.firebaseapp.com",
    databaseURL: "https://easyweb-a3e80-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "easyweb-a3e80",
    storageBucket: "easyweb-a3e80.appspot.com",
    messagingSenderId: "103085244545",
    appId: "1:103085244545:web:0d0380dcca16691e078d80",
    measurementId: "G-0H9J3BJY5F"
  },
  selbsthilfe: {
    apiKey: "AIzaSyA2kQPxd44unc23ACCpkXafo3pzKQ3ifUk",
    authDomain: "easyweb-selbsthilfe.firebaseapp.com",
    databaseURL: "https://easyweb-selbsthilfe-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "easyweb-selbsthilfe",
    storageBucket: "easyweb-selbsthilfe.appspot.com",
    messagingSenderId: "527088478578",
    appId: "1:527088478578:web:7bf1a5b79a333db14655eb",
    measurementId: "G-FSRS19SYLP"
  },
  herzverein: {
    apiKey: "AIzaSyAAp47N0ltur1X9hSUObWnxbLDcKJ_uxuA",
    authDomain: "easyweb-herzverein.firebaseapp.com",
    databaseURL: "https://easyweb-herzverein-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "easyweb-herzverein",
    storageBucket: "easyweb-herzverein.appspot.com",
    messagingSenderId: "1090259970084",
    appId: "1:1090259970084:web:18f96552b162f9e19550b1",
    measurementId: "G-Z63B40G6EK"
  },
  seltenerkrebs: {
    apiKey: "AIzaSyDQBFlMeLddGfQ0-Crvl46imdVBAQmzfRY",
    authDomain: "easyweb-seltenerkrebs.firebaseapp.com",
    databaseURL: "https://easyweb-seltenerkrebs-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "easyweb-seltenerkrebs",
    storageBucket: "easyweb-seltenerkrebs.appspot.com",
    messagingSenderId: "1085244620589",
    appId: "1:1085244620589:web:dd0e2ec74cdbd3a6c3dda4",
    measurementId: "G-ZJJ1WFD58E"
  }, 
  diabetes: {
    apiKey: "AIzaSyC3htS1xOsgIwzQ-FXtnBgoOBmqhJIwHpU",
    authDomain: "easyweb-diabetes.firebaseapp.com",
    databaseURL: "https://easyweb-diabetes-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "easyweb-diabetes",
    storageBucket: "easyweb-diabetes.appspot.com",
    messagingSenderId: "562950677157",
    appId: "1:562950677157:web:9b78923b4d11ae75dd234c",
    measurementId: "G-RRWWY8ZPS8"
  }
};
export default FIREBASE_CONFIG;